import React from 'react';
import './App.css';
import StationsTable from './components/StationsTable';
import Pitch from './components/Pitch';

function App() {
  return (
    <div className="App">
      <h1>WaterWise</h1>
      <Pitch/>
      <StationsTable/>
    </div>
  );
}

export default App;
