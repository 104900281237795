import { Station } from "../types/Station";

const BASE_API_URL = "/api";
console.log("API_URL: ", BASE_API_URL);

export const getStations = async (dataset: string): Promise<Station[]> => {
    let getStationsURL = "";
    if (dataset === "industrial-wastewater") {
        getStationsURL = `${BASE_API_URL}/industrial-wastewater/v1/stations`;
    } else if (dataset === "inland-lake-drinking-water-quality") {
        getStationsURL = `${BASE_API_URL}/inland-lake/v1/stations`;
    } else {
        getStationsURL = `${BASE_API_URL}/stream/v1/stations`;
    }
    console.log(`Fetching stations from ${dataset} at ${getStationsURL}`);
    try {
        const response = await fetch(getStationsURL);
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const stations = await response.json();
        console.log("API response: ", stations);
        return stations;
    } catch (error) {
        console.error("Error fetching stations: ", error);
        return [];
    }
};