import React, { useState, useEffect } from 'react';
import { getStations } from '../services/stationsService';
import { Station } from '../types/Station';
import IndustrialWastewaterTable from './IndustrialWastewaterTable';
import LakeWaterQualityTable from './LakeWaterQualityTable';
import StreamWaterQualityTable from './StreamWaterQualityTable';
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';

const StationsTable: React.FC = () => {
    const [selectedDataset, setSelectedDataset] = useState<string>("industrial-wastewater");
    const [stations, setStations] = useState<Station[]>([]);
    const [tableComponent, setTableComponent] = useState<JSX.Element | null>(<IndustrialWastewaterTable stations={stations}/>);

    const handleChangeSelectedDataset = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newDataset = event.target.value;
        setSelectedDataset(newDataset);
    }

    useEffect(() => {
        getStations(selectedDataset).then((data) => setStations(data));
    }, [selectedDataset]);

    useEffect(() => {
        if (selectedDataset === "industrial-wastewater") {
            setTableComponent(<IndustrialWastewaterTable stations={stations}/>);
        } else if (selectedDataset === "inland-lake-drinking-water-quality") {
            setTableComponent(<LakeWaterQualityTable stations={stations}/>);
        } else {
            setTableComponent(<StreamWaterQualityTable stations={stations}/>);
        }
    }, [stations])

    return (
        <div className="card">
            <div>
                <label style={{ marginRight: "8px", padding: 0, fontSize: "3em", fontWeight:"bold"}}>Stations </label>
            </div>
            <div>
                <label htmlFor="dataset-selector" style={{ marginRight: "8px" , padding: 0}}>Selected Data Set: </label>
                <select
                    id="dataset-selector"
                    value={selectedDataset}
                    onChange={handleChangeSelectedDataset}
                >
                    <option value="industrial-wastewater">Industrial Wastewater By Facility</option>
                    <option value="inland-lake-drinking-water-quality">Inland Lake Drinking Water Quality</option>
                    <option value="stream-water-quality">Stream Water Quality</option>
                </select>
            </div>

            <div>{tableComponent}</div>
        </div>
    )
}

export default StationsTable;