import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Station } from '../types/Station';
import { Reading } from '../types/Reading';
import { getStreamWaterReadings } from '../services/readingsService';

interface StreamWaterQualityTableProps {
    stations: Station[]
}

const StreamWaterQualityTable: React.FC<StreamWaterQualityTableProps> = (props) => {
    const [selectedStation, setSelectedStation] = useState<Station | null>(null);
    const [readings, setReadings] = useState<Reading[]>([]);
    const [displayDialog, setDisplayDialog] = useState(false);

    const onRowSelect = (event: { data : Station }) => {
        setSelectedStation(event.data);
        setDisplayDialog(true); // open modal on row selection
    }

    const closeDialog = () => {
        setDisplayDialog(false);
        setSelectedStation(null);
        setReadings([]);
    }

    useEffect(() => {
        getStreamWaterReadings(selectedStation?.stationDetails)
            .then((data) => setReadings(data));
    }, [selectedStation])

    const dialogStyle = {
        width: '80vw',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        'overflow-x': 'scroll',
    };

    return (
        <div className="card">
            <DataTable 
                value={props.stations}
                selectionMode="single" 
                selection={selectedStation!} onSelectionChange={(e) => setSelectedStation(e.value)}  
                onRowSelect={onRowSelect}
                dataKey="stationDetails" 
                metaKeySelection={false} 
                tableStyle={{ minWidth: '50rem', maxWidth: '95vw', margin: '2.5vw' }}
            >
                <Column field="lastUpdated" header="Last Updated"></Column>
                <Column field="stationDetails" header="Station Details"></Column>
            </DataTable>
            <Dialog
                header="Station Readings"
                visible={displayDialog}
                style={dialogStyle}
                onHide={closeDialog} // Close dialog on hide
                className="modal"
            >
                {selectedStation && (
                    <DataTable value={readings} dataKey="date">
                        <Column field="parameterCode" header="Parameter Code"></Column>
                        <Column field="parameterDescription" header="Parameter Description"></Column>
                        <Column field="year" header="Year"></Column>
                        <Column field="date" header="Date"></Column>
                        <Column field="time" header="Time"></Column>
                        <Column field="fieldNumber" header="Field Number"></Column>
                        <Column field="remarkCode" header="Remark Code"></Column>
                        <Column field="result" header="Measured Result"></Column>
                        <Column field="valueQualifier" header="Value Qualifier"></Column>
                        <Column field="units" header="Units"></Column>
                        <Column field="analysisMethod" header="Analysis Method"></Column>
                    </DataTable>
                )}
            </Dialog>
        </div>
    )
}

export default StreamWaterQualityTable;
