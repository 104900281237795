import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Station } from '../types/Station';
import { Reading } from '../types/Reading';
import { getLakeWaterReadings } from '../services/readingsService';

interface LakeWaterQualityTableProps {
    stations: Station[]
}

const LakeWaterQualityTable: React.FC<LakeWaterQualityTableProps> = (props) => {
    const [selectedStation, setSelectedStation] = useState<Station | null>(null);
    const [readings, setReadings] = useState<Reading[]>([]);
    const [displayDialog, setDisplayDialog] = useState(false);

    const onRowSelect = (event: { data : Station }) => {
        setSelectedStation(event.data);
        setDisplayDialog(true); // open modal on row selection
    }

    const closeDialog = () => {
        setDisplayDialog(false);
        setSelectedStation(null);
        setReadings([]);
    }

    useEffect(() => {
        getLakeWaterReadings(selectedStation?.stationDetails)
            .then((data) => setReadings(data));
    }, [selectedStation])

    const dialogStyle = {
        width: '80vw',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        'overflow-x': 'scroll',
    };

    return (
        <div className="card">
            <DataTable 
                value={props.stations}
                selectionMode="single" 
                selection={selectedStation!} onSelectionChange={(e) => setSelectedStation(e.value)}  
                onRowSelect={onRowSelect}
                dataKey="stationDetails" 
                metaKeySelection={false} 
                tableStyle={{ minWidth: '50rem', maxWidth: '95vw', margin: '2.5vw' }}
            >
                <Column field="lastUpdated" header="Last Updated"></Column>
                <Column field="lakeName" header="Lake Name"></Column>
                <Column field="stationCode" header="Station Code"></Column>
                <Column field="stationDetails" header="Station Details"></Column>
            </DataTable>
            <Dialog
                header="Station Readings"
                visible={displayDialog}
                style={dialogStyle}
                onHide={closeDialog} // Close dialog on hide
                className="modal"
            >
                {selectedStation && (
                    <DataTable value={readings} dataKey="sampleDate">
                        <Column field="sampleDate" header="Sample Date"></Column>
                        <Column field="granAlkalinity" header="Gran Alkalinity (mg/L)"></Column>
                        <Column field="calcium" header="Calcium (mg/L)"></Column>
                        <Column field="chloride" header="Chloride (mg/L)"></Column>
                        <Column field="specificConductance" header="Specific Conductance (mcS/cm)"></Column>
                        <Column field="dic" header="DIC (mg/L)"></Column>
                        <Column field="doc" header="DOC (mg/L)"></Column>
                        <Column field="iron" header="Iron (mcg/L)"></Column>
                        <Column field="potassium" header="Potassium (mg/L)"></Column>
                        <Column field="magnesium" header="Magnesium (mg/L)"></Column>
                        <Column field="sodium" header="Sodium (mg/L)"></Column>
                        <Column field="ammoniumAmmonia" header="Ammonium/NH3 (mcg/L)"></Column>
                        <Column field="nitrateNitrite" header="Nitrate/Nitrite (mcg/L)"></Column>
                        <Column field="tkn" header="Total Kjeldahl Nitrogen (mcg/L)"></Column>
                        <Column field="tn" header="Total Nitrogen (mcg/L)"></Column>
                        <Column field="ph" header="pH"></Column>
                        <Column field="totalPhosphorus" header="Total Phosphorus (mcg/L)"></Column>
                        <Column field="silicate" header="Silicate (mg/L)"></Column>
                        <Column field="sulfate" header="Sulfate (mg/L)"></Column>
                        <Column field="lastUpdated" header="Last Updated"></Column>
                    </DataTable>
                )}
            </Dialog>
        </div>
    )
}

export default LakeWaterQualityTable;
