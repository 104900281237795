import React from "react";

function Pitch() {
    return (
    <div>
        <p>
            WaterWise is revolutionizing the way we combat industrial water pollution. As ESG (Environmental, Social, and Governance) standards rise, companies are under more pressure than ever to adopt sustainable practices. WaterWise is the solution they&apos;ve been waiting for—a cutting-edge platform that delivers real-time insights into water quality, 
            helping businesses and regulators pinpoint areas where industrial activity is causing harm. With features like interactive maps, heatmaps, and customizable graphs, 
            WaterWise transforms complex data into clear, actionable insights.
        </p>
        <p>
            Imagine the impact. 
            Communities, regulators, and businesses will have the power to track pollution, take immediate action, and hold industries accountable like never before. 
            WaterWise isn&apos;t just about data—it&apos;s about empowering you to drive meaningful change while aligning with growing ESG standards.
        </p>
        <p>
            Now is the time to act. 
            By investing in WaterWise, youre not just backing an innovative platform, you&apos;re positioning yourself at the forefront of a massive shift in corporate responsibility. 
            This is your chance to be part of a game-changing movement in environmental stewardship, one that&apos;s meeting the urgent demand for sustainability head-on. 
            Join us in transforming the future of water management—don&apos;t just invest, lead the way!
        </p>
    </div>
    )
}

export default Pitch;