import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Station } from '../types/Station';
import { Reading } from '../types/Reading';
import { getWastewaterReadings } from '../services/readingsService';

interface IndustrialWastewaterTableProps {
    stations: Station[]
}

const IndustrialWastewaterTable: React.FC<IndustrialWastewaterTableProps> = (props) => {
    const [selectedStation, setSelectedStation] = useState<Station | null>(null);
    const [readings, setReadings] = useState<Reading[]>([]);
    const [displayDialog, setDisplayDialog] = useState(false);

    const onRowSelect = (event: { data : Station }) => {
        setSelectedStation(event.data);
        setDisplayDialog(true); // open modal on row selection
    }

    const closeDialog = () => {
        setDisplayDialog(false);
        setSelectedStation(null);
        setReadings([]);
    }

    useEffect(() => {
        getWastewaterReadings(selectedStation?.companyCode)
            .then((data) => setReadings(data));
    }, [selectedStation])

    const dialogStyle = {
        width: '80vw',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        'overflow-x': 'scroll',
    };

    return (
        <div className="card">
            <DataTable 
                value={props.stations}
                selectionMode="single" 
                selection={selectedStation!} onSelectionChange={(e) => setSelectedStation(e.value)}  
                onRowSelect={onRowSelect}
                dataKey="companyCode" 
                metaKeySelection={false} 
                tableStyle={{ minWidth: '50rem', maxWidth: '95vw', margin: '2.5vw' }}
            >
                <Column field="lastUpdated" header="Last Updated"></Column>
                <Column field="sector" header="Sector"></Column>
                <Column field="worksName" header="Workstation Name"></Column>
                <Column field="companyCode" header="Company Code"></Column>
                <Column field="municipality" header="Municipality"></Column>
            </DataTable>
            <Dialog
                header="Station Readings"
                visible={displayDialog}
                style={dialogStyle}
                onHide={closeDialog} // Close dialog on hide
                className="modal"
            >
                {selectedStation && (
                    <DataTable value={readings} dataKey="controlPointID">
                        <Column field="parameterName" header="Parameter Name"></Column>
                        <Column field="parameterReportedAs" header="Parameter Reported As"></Column>
                        <Column field="resultStructure" header="Result Structure"></Column>
                        <Column field="componentType" header="Component Type"></Column>
                        <Column field="measuredValue" header="Value"></Column>
                        <Column field="unitOfMeasure" header="Unit of Measure"></Column>
                        <Column field="regulation" header="Regulation"></Column>
                        <Column field="sampleDate" header="Sample Date"></Column>
                        <Column field="controlPointName" header="Control Point Name"></Column>
                        <Column field="controlPointId" header="Control Point ID"></Column>
                        <Column field="frequency" header="Frequency"></Column>
                        <Column field="lastUpdated" header="Last Updated"></Column>
                    </DataTable>
                )}
            </Dialog>

        </div>
    )
}

export default IndustrialWastewaterTable;
